.document-list {

  .ant-upload-list {
    display: none;
  }

  > .ant-card-body {
    padding: 6px 6px 0 6px;
  }

  &-content {
    display: grid;
    grid-row-gap: 6px;
    max-height: 200px;
    overflow-y: scroll;


    &-loading-more {
      padding: 5px 0 10px;
      justify-self: center;
    }

    &-skeleton {
      display: grid;
      grid-template-columns: 1fr;
      grid-row-gap: 6px;
      .ant-skeleton {
        width: 100%;
      }
    }

    &-item {
      cursor: pointer;
      .ant-card-body {
        padding: 4px 12px;
      }

      &.success {
        background-color: #c6eabb;
      }

      &.not_success {
        background-color: #F9CCCC;
      }

      &.recognition {
        background-color: #f1efb5;
      }

      &-content {
        display: grid;
        grid-template-columns: 1fr auto auto;
        grid-column-gap: 12px;
        align-items: center;
      }
    }


    .empty {

      padding: 10px;
    }
  }
}


.document-list-popup-content {
  hr {
    margin: 4px 0;
  }
}
