.pay-distribution {

  .ant-card-head-title{
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: rgba(0, 0, 0, 0.88);
    font-weight: 600;
    font-size: 16px;
  }

  .pay-distribution-content {
    display: flex;
    flex-direction: column;
    margin: 0 0 16px 0;

    &__switches {
      display: flex;
      gap: 16px;
    }

    > :not(:last-child) {
      margin: 0 0 8px 0;
    }

    .ant-input-number-input {
      color: rgba(0, 0, 0, 0.88)!important;
    }

    &__contracts {
      display: grid;
      padding: 6px 8px 6px 0;

      &-title {
        font-size: 13px;
        margin: 0 0 -10px 0;
      }

      &-item {
        display: grid;
        grid-template-columns: 2fr 1fr;
        align-items: center;
        grid-column-gap: 8px;
        padding: 8px 0;

        &:not(:last-child) {
          border-bottom: 1px solid #EEF4FB;
        }

        &__controls {
          display: grid;
          grid-template-columns: 1fr 32px;
          grid-column-gap: 8px;

          .ant-input-number-disabled {
            input {
              color: rgba(0, 0, 0, 0.88)!important;
            }
          }
        }
      }
    }
  }
  .pay-distribution-footer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 8px;
  }
  .pay-distribution-view {
    &-item {
      margin: 0 0 12px 0;
      .hard-button-link {
        width: fit-content;
        .AppTextStyle {
          font-size: 15px;
        }
      }
    }
  }
}