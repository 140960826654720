.user-card-popup-tab-personal-account {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 30px;
  &-left {
  }
  &-right {

  }
  &-title {
    margin: 0 0 10px 0;
  }
  &-data {

    &-list {
      display: grid;
      margin: 0 0 40px 0;

      &-item {
        display: grid;
        grid-template-columns: 1fr 1fr;
        padding: 5px 0;

        &:not(:last-child) {
          border-bottom: 1px solid #EEF4FB;
        }
      }
    }

  }
}