.documents__upload {
    border-radius: 5px;
    background: rgba(0, 126, 236, 0.2);
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    position: relative;
    outline: none;

    &-content {
        text-align: center;
    }

    & .btn {
        margin: 0;
    }

    & label {
        margin: 0;
    }

    &-btn {
    }

    &-text {
        display: block;
        color: #5D9ACF;
    }
    &-input {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        opacity: 0;
        cursor: pointer;
    }
}