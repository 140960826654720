.user-card-popup-tab-contracts {
  &-button {
    margin: 20px 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &-empty {
    grid-row-gap: 50px;
    margin: 50px 0;
  }
}