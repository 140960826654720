.searchItem {
    position: relative;

    &-clear {
        font-size: 25px;
        margin-right: 20px;
        cursor: pointer;
        max-height: 21px;
        display: flex;
        align-items: center;
        justify-content: start;
    }

    &-btn {
        margin-left: 8px;
    }
}
