.pay-modal {
  &.app-ui-modal {
    .ant-modal-content {
      .ant-modal-body > {
        .Title {
          span {
            text-align: left!important;
          }
        }
      }
    }
  }
  &-content {
    display: grid;
    grid-template-columns: 1fr 3fr;
    grid-gap: 20px;

    &__requisites {
      display: grid;
      grid-gap: 16px;

      hr {
        margin: 0 0 2px 0;
      }
    }

    &__tables {
      display: flex;
      flex-direction: column;

      .payment-assignment-select {
        margin: 0 0 20px 0;
        display: grid;
        grid-template-columns: minmax(200px, 650px) 1fr;
        grid-column-gap: 16px;
        align-items: flex-end;
      }

      .ant-card {
        margin: 0 0 20px 0;
      }
    }
  }
}