.user-card-popup-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  &-left {
    display: grid;
    align-items: center;

    &-title {
      display: grid;
      align-items: center;
      grid-template-columns: 30px 1fr;
      grid-column-gap: 5px;
    }
  }
  &-right {
    display: grid;
    grid-template-columns: max-content 1fr;
    align-items: center;
    grid-column-gap: 20px;

    .ant-btn {
      height: 40px;
    }
  }
}