.debts-main-table {
  width: max(100%, 900px);
  overflow: auto hidden;

  .ant-table-tbody {

    .ant-table-cell {

      .ant-skeleton-title {
        height: 45px;
      }
    }
  }
  .buttons-container {
    display: grid;
    grid-row-gap: 10px;
  }
}
.debts-notify-datepicker {
  margin-top: 10px;
}

.table-notify-datepicker {
  z-index: 9999;
}