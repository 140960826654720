.group-action-collapse-wrapper {
  height: calc(100vh - 160px);
  overflow-y: auto;
}

.group-action-collapse-table {
  background: rgba(0, 0, 0, 0);

  .ant-collapse-item {
    .ant-collapse-header {
      padding: 0;
      padding-inline-start: 0!important;

      &-text {

        .group-action-collapse-table-item {
          display: grid;
          grid-template-columns: 82px 1fr max-content;
          align-items: center;
          grid-column-gap: 30px;
          padding: 16px 32px;

          &.planned {
            grid-template-columns: 1fr max-content;
          }
        }

        .group-action-collapse-table__status {
          display: flex;
          flex-direction: column;
          gap: 8px;

          .ant-tag {
            width: fit-content
          }
        }

        .group-action-collapse-table__title {
          display: flex;
          flex-direction: column;
          gap: 8px;


          &-footer {
            display: flex;

            &-item {
              display: flex;
              align-items: center;
              justify-content: center;
              margin: 0 20px 0 0;
            }
          }
        }

        .group-action-collapse-table__controls {
          display: flex;
          gap: 20px;
          align-items: center;
        }
      }
    }

  }

  .ant-collapse-content {
    border-top: none;

    .group-action-collapse-table__content {
      display: flex;
      flex-direction: column;
      gap: 18px;

      &_filters {
        display: flex;
        gap: 10px;
      }
    }
  }
}