.sidebar-new {
  height: 100vh;
  box-shadow: 1px 0 20px 0 #dfdfe3;

  .sidebar-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 6px 5px 10px;

    &__logo {
      cursor: pointer;
    }

    &__button {
      grid-area: grid-button;
      cursor: pointer;
    }

    &__burger {
      font-size: 25px
    }
  }

  .sidebar-menu__divider { margin: 12px 0;

    &-footer {
      margin: 0 0 12px 0
    }
  }

  .sidebar-username {
    margin-left: 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .sidebar-scrollable-menu-container {
    overflow-y: auto;
    overflow-x: hidden;
    height: calc(100vh - 225px);

     &::-webkit-scrollbar {
      width: 5px;
    }

    &::-webkit-scrollbar-track {
      background-color: #f3f5f5;
      border-radius: 100px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #bae0ff;
      border-radius: 100px;
    }

    .sidebar-menu {
      border-radius: 6px;
      width: 240px;
      margin-left: 10px;
      border-inline-end: unset!important;

      .ant-menu-submenu {
        .ant-menu-submenu-title {
          padding-left: 5px!important;
        }
      }
      .ant-menu-item:not(.ant-menu-item-only-child) {
        padding-left: 5px!important;
      }

      .ant-menu-submenu-selected {
        .ant-menu-submenu-title {
          color: #1677ff;
        }
      }
    }
  }

  .sidebar-footer {
    display: grid;
    grid-template-columns: 1fr;
    grid-row-gap: 15px;
    margin: 0 5px;

    .app-button-text {
      span {
        text-wrap: nowrap;
      }
    }
  }

  &.collapsed {
    .sidebar-header {
      padding: 10px 0 5px 20px;
    }
    .sidebar-menu {
      margin: 0;

      .ant-menu-item {
        height: 30px!important;
      }


      .ant-menu-item:not(.ant-menu-item-only-child) {
        padding-left: 24px!important;
      }

      .ant-menu-submenu {
        .ant-menu-submenu-title {
          padding-left: 24px!important;
          height: 30px!important;
        }
      }
    }
    .sidebar-footer {
      .app-ui-button-container {
        .app-button {
          padding: 0;
        }
      }
    }
  }
}