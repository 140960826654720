.available-actions-popup {
  .ant-popover-content {
    max-width: 500px;
    .ant-popover-inner-content {
      .form-generator-controls {
        grid-template-columns: 1fr;
      }
    }

    .app-ui-select {
      max-width: 476px;
    }
  }
}
