.tasks-main-table {
  width: max(100%, 900px);
  overflow: auto hidden;


  .number-cell {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  .ant-table-tbody {

    .ant-table-cell {
      .ant-skeleton-title {
        height: 45px;
      }
    }
  }

  .buttons-container {
    display: grid;
    grid-row-gap: 10px;
  }
}