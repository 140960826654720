@import './assets/fonts/feather/icon-font.css';
@import './assets/fonts/datta/datta-icons.css';

.popover {
    max-width: unset;
}

.shape-purple {
    background-color: #f6f4fe;
    color: #6746f5;
}

.icon-download:before {
    content: "\e901";
}

.back {
    font-size: 20px;
    color: #000;
    cursor: pointer;

    display: flex;
    align-items: center;
}

.back span {
    font-size: 15px;
    font-weight: bold;
    text-transform: uppercase;
    margin-left: 10px;
}

.pcoded-main-container {
    /*margin-top: -50px;*/
}

.is-hiding-support-bubble .woot--bubble-holder {
    display: none;
}

.accordion > .card {
    overflow: visible !important;
}
