.statistic-card {
  &-content {
    display: grid;
    grid-template-columns: 1fr;
    grid-column-gap: 20px;
  }

  &-info {

    &-item {
      display: grid;
      grid-template-columns: 2fr 1fr 1fr;
      padding: 6px 2px;
      position: relative;

    > div:not(.title) {
      justify-self: flex-end;
    }

      &:not(.main) {
        .title {
          margin: 0 0 0 12px;
        }
      }

      &:not(:last-child) {
        border-bottom: 1px solid #EEF4FB;
      }

      &__icon {
        position: absolute;
        left: -20px;
        top: 1px;
      }

      &__ruble{
        display: flex;
        align-items: center;
        position: relative;
        margin: 0 15px 0 0;

        svg {
          position: absolute;
          top: 2px;
          right: -20px;
          width: 20px!important;
          height: 20px!important;
        }
      }
    }
  }
}