.sidebar {
  width: 20%;
  min-height: 100%;
  max-width: 300px;
  min-width: 235px;
  overflow-y: visible !important;
  background-color: #f4f7fa;
  border: none;
  box-shadow: none !important;
}

.sidebar-root {
  min-height: 100%;
  margin-top: -70px;
  overflow: visible !important;
}

.processes{
  &__wrapper {
    width: 100%;
    transition-duration: 0.3s;
  }

  &__table {
    thead {
      th {
        border-top: 0;
        border-bottom: 1px solid rgb(241, 241, 241);
      }
    }
  }

  &__card {
    padding-left: 0 !important;
  }
}
