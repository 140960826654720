.assignment-modal {
  .ant-modal-content {
    .ant-modal-body > {
      .Title {
        span {
          text-align: left!important;
        }
      }
    }
  }
  &-content {
    display: grid;
    grid-template-columns: minmax(200px, 345px) 2fr;
    grid-column-gap: 20px;


    hr {
      margin: 0 0 2px 0;
    }

    &_add-pay {
      margin: 0 0 16px 0;

      &__title {
        margin: 0 0 8px 0;
      }
      &__inputs {
        display: grid;
        grid-template-columns: minmax(200px, 591px) 1fr;
        grid-column-gap: 16px;
        align-items: flex-end;
      }
    }
  }
}