.group-actions-create-page {
  padding-bottom: 20px;
  .filters-container {
    margin-bottom: 12px;
    display: grid;
    align-items: center;
    grid-template-columns: 1fr 200px;
    grid-column-gap: 20px;

    .app-button {
      margin-top: 37.25px;
    }
  }
}
