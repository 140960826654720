.user-card-popup-history-table {
  display: grid;
  grid-gap: 20px;
  &-filters {
    display: grid;
    grid-gap: 20px;
    &-dates {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 20px;
    }
    &-checkboxes {
      .ant-checkbox+span {
        padding-inline-end: 20px;
      }
    }
  }

  .sum-td {
    min-width: 90px;
  }
}