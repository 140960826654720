.table-content-cell {
 display: flex;
  .left-column {
    display: flex;
    flex-direction: column;
    .center {
      display: flex;
      flex-direction: column;
      margin: 0 0 0 30px
    }
  }

  .right-column {
    .right {
      margin: 0 0 0 10px;
    }
  }
}