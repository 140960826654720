.icon--green {
    color: #00AF01;
    border: 1px solid rgba(162,162,195,.2);
    border-radius: 3px;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover, &:active, &:focus {
        color: #00AF01;
    }

    &:hover {
        border-color: #00AF01;
    }
}
