.user-card-popup-tab-agreements {

  &-loader {
    display: grid;
    grid-template-columns: 1fr;
    grid-row-gap: 20px;

    .ant-skeleton {
      width: 100%;

      .ant-skeleton-button {
        height: 156px;
        width: 100%;
        border-radius: 14px;
      }
    }
  }

  &-empty {
    grid-row-gap: 50px;
    margin: 50px 0;
  }
}