.toast__container {
    position: fixed;
    right: 20px;
    top: 20px;
    z-index: 10000;
    
    display: flex;
    flex-direction: column;
}

.toast {
    margin-bottom: 20px;
    max-height: 250px;
    overflow: hidden;
    flex-basis: unset;
}

.toast.error {
    background-color: #EA5959;
    color: #fff;
}


.toast.success {
    background-color: #32946E;
    color: #fff;
}
