.processes-monitoring-page {
  .ant-card-head-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .counter {
    b {
      font-size: 16px;
    }
  }
}