.table-item {
    position: relative;
}

.table-item-name {
    position: relative;

    &__title {
        color: #888888;
        margin-right: 8px;
    }

    &__container {
        display: flex;

    }

}

.table-item-name:hover::before {
    content: attr(data-name);
    position: absolute;
    bottom: 20px;
    background-color: #fff;
    padding: 10px;
    border-radius: 5px;
}

.table-item__loading {
    position: absolute;
    width: 90%;
    z-index: 1000;
    height: 20px;
    display: flex;
    justify-content: center;
    height: 49px;
}

.table-item__grid {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 20px 20px;

    h6 {
        margin: 10px 0 10px 0 !important;
    }
}

.process-signal-item {
    height: 21px;
    margin: 0;
    max-width: unset !important;
}

.table-item-number {
    color: #111;
    margin-bottom: 8px;
}

.table-item-student {
    display: flex;
    align-items: center;
}

.table-item-student .mail {
    margin-right: 5px;
    cursor: pointer;
}

.table-item-student .icon-message-circle::before {
    font-size: 20px;
}

.table-item-student .icon-message-circle.unread {
    position: relative;
}

.table-item-student .icon-message-circle.unread::after {
    position: absolute;
    content: "";
    right: -5px;
    top: -10px;


    border-radius: 50%;
    width: 10px;
    height: 10px;
    background-color: red;
}