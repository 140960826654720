.process-monitoring-main-table {
  width: max(100%, 900px);
  overflow: auto hidden;

  .ant-table-tbody {

    .ant-table-cell {

      .ant-skeleton-title {
        height: 45px;
      }
    }
  }

  .status-button {
    border-radius: 15px;
    border: 1px solid black;
    height: 14px;
    width: 10px;

    &.orange {
      background: orange;
    }
    &.red {
      background: red;
    }
    &.black {
      background: black;
    }
  }

  .content-cell {
    display: flex;
    align-items: center;
  }
}