.create-edit-pay-popup {
  .ant-popover-inner {
    width: 600px;

    .create-edit-pay-popup-content {
      display: grid;
      grid-row-gap: 8px;
      margin: 0 0 16px 0;

      &__contracts {
        display: grid;
        grid-row-gap: 8px;
        padding: 6px 8px 6px 20px;
        &-item {
          display: grid;
          grid-template-columns: 2fr 1fr;
          align-items: center;
          grid-column-gap: 8px;

          &__controls {
            display: flex;
            align-items: center;
            justify-content: space-between;
            grid-column-gap: 8px;
          }
        }
      }
    }
    .create-edit-pay-popup-footer {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 8px;
    }
  }
}