.user-card-popup-actions {
  .ant-popover-content {
    max-width: 1014px;
    .ant-popover-inner-content {
      .form-generator-controls {
        grid-template-columns: 1fr;
      }
    }

    .app-ui-select {
      min-width: 990px;
    }
  }
}
