.search-item{
  &__wrapper {
    border-radius: 0;
    overflow: hidden;

    .actions-filter {
      &__wrapper {
        padding: 0 !important;
      }

      &__header {
        display: none;
      }

      &__search {
        margin-bottom: 10px;
      }
    }

    .education-format-filter {
      &__wrapper {
        padding: 0 !important;
      }

      &__header {
        display: none;
      }
    }

    .tariff-filter, .school-filter {
      &__wrapper {
        input, label {
          cursor: pointer;
        }
        padding: 0 !important;
      }

      &__header {
        display: none;
      }


    }
  }
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: baseline;

    & > p {
      color: #111;
      font-size: 14px;
      font-weight: 400;
      margin: 0;
    }
    & > svg {
      margin-left: auto
    }

    &:hover {
      & > svg {
        color: black;
        transition-duration: 0.2s;
      }
    }
  }
  &__clear {
    font-size: 25px;
    cursor: pointer;
    max-height: 21px;
    display: flex;
    align-items: center;
    justify-content: start;
  }
}
