.processes-filters {
  &__container {
    padding: 20px 20px 0 20px;
  }

  &__accordion {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    grid-gap: 20px;

    .alternative-month-input {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }

    .search-item {
      &__header {
        border-bottom: none;
      }

      &__wrapper {
        max-height: 90px !important;

        &-focused {
          max-height: unset !important;
        }

        &-clearable {
          box-shadow: 0 0 4px 6px rgb(4 169 245 / 30%);
        }
      }
    }
  }
}
