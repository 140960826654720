.user-card-popup-tab-bonuses {
  &__title {
    margin: 12px 0 16px 0;
  }
  &__filters {
    display: flex;
    gap: 20px;
    &-selects {
      display: flex;
      gap: 20px;
    }
    &-checkboxes {
      display: flex;
      align-items: flex-end;
    }
  }
  &__table {
    margin: 12px 0 0 0;

    .ant-tag {
      padding: 2px 6px;
    }
  }
}